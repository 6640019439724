import React, {useEffect} from "react"
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import {allDispatchFormType} from "../utils/model";
import queryString from 'query-string'
import {sortBy} from "../utils/commonUtils";

type FormTemplateProps = {
    data:  {
        form: allDispatchFormType
    }
    pageContext: { formId }
}

function FormTemplate(props: FormTemplateProps) {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.airtable.com/js/embed/embed_snippet_v1.js";
        script.async = true;
        document.body.appendChild(script);
        document.body.className = "page-form"
        return () => {
          document.body.removeChild(script);
        }
    }, []);

    const raw = props.data.form

    const formURL = `https://airtable.com/embed/${raw.data.Form}?backgroundColor=transparent`

    return (
        <Layout contentClassName="bg-gray-100">
           <SEO title={raw.data.Name}
            description={raw.data.Name} 
            gtmPageType="contact" 
            gtmPageName={raw.data.Name}/>
            <div className="iframe-container mx-2 pt-6 md:mx-auto lg:max-w-2xl">
                {/* <h1 className="font-bold font-4xl mt-16 mb-16">{raw.data.Name}</h1> */}
                <iframe className="relative airtable-embed airtable-dynamic-height" src={formURL} frameBorder="0" width="100%" height="3454" style={{background: "transparent"}}></iframe>
            </div>
        </Layout>
    )
}

export const query = graphql`
query FormQuery($formId: String) {
    form: airtable(table: { eq: "DispatchForm" }, id: { eq: $formId }) {
        id
        data {
            Name
            Form
        }
    }
}
`

export default FormTemplate
